import React, { useState, useEffect } from 'react';
import './App.css';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import StoryTimeLogin from './StoryTimeLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Example: Using the faBars (hamburger) icon


Amplify.configure(awsconfig);

function App() {

  const primaryColor = '#007BFF';
  //const backgroundColor = '#F7F7F7';
  const textColor = '#FFFFFF'; // White text color
  const [loading, setLoading] = useState(false);
  const [storyTitle, setStoryTitle] = useState("Your Custom Story");
  const [audioURL, setAudioURL] = useState(null);
  const [characterName, setCharacterName] = useState('');
  const [targetAge, setTargetAge] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [storyContent, setStoryContent] = useState(' '); // New state for story content
  const [selectedVoice, setSelectedVoice] = useState('Joanna'); // Default voice
  const [selectedSpeed, setSelectedSpeed] = useState('Normal'); // Default speed
  const [user, setUser] = useState(null); // Initialize user state
  const [fontSize, setFontSize] = useState('small'); // Default font size
  const [storyLibrary, setStoryLibrary] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);


  const stories = [
    {
      title: "",
      author: "",
      content: ""
    },
    // Add more stories as needed
  ];

  useEffect(() => {
    async function checkUser() {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        setUser(authUser);
        const userId = authUser.attributes.sub;
        setUserId(userId);
        console.log ('User ID:', userId);
        const userAttributes = await Auth.userAttributes(authUser);
        const nameAttribute = userAttributes.find(attribute => attribute.Name === 'name');

        if (nameAttribute) {
          setUserName(nameAttribute.Value);
        }

      } catch (error) {
        setUser(null);
      }
    }
    checkUser();
  }, []);

  useEffect(() => {
    async function fetchLibraryData() {
      if (user) {
        // Fetch the user's story library here using the API
        const apiKey = 'iCTpxJMiyG53J5TpRAad35zUdEMUxItj7UrNhv9K';
        const apiUrl = 'https://z1v8gw606l.execute-api.us-east-1.amazonaws.com/test/getlibrary';
  
        const headers = new Headers({
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        });
  
        const body = {
          userID: userId
        }

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          });        
          
          console.log('Response status:', response.status);  

          if (response.ok) {
            const data = await response.json();
            const bodyData = JSON.parse(data.body);
            setStoryLibrary(bodyData);
          } else {
            console.error('Failed to fetch user library data:', response.statusText);
          }

        } catch (error) {
          console.error('An error occurred:', error);
          setStoryContent('An error occurred:', error);
        }
      }
    }
  
    fetchLibraryData(); // Call the fetchLibraryData function
  
    // Add any other cleanup or dependencies as needed
  }, [user,userId]);

  // Function to fetch the story when a title is clicked
  const handleStoryTitleClick = async (storyId) => {
    // Fetch the story using the storyId
    const apiKey = 'iCTpxJMiyG53J5TpRAad35zUdEMUxItj7UrNhv9K';
    const apiUrl = `https://z1v8gw606l.execute-api.us-east-1.amazonaws.com/test/fetchstory`; // Replace with your API URL

    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    });

    console.log(storyId);

    const body = {
      story_id: storyId
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      console.log('Response status:', response.status);  

      if (response.ok) {
        const data = await response.json();
        const bodyData = JSON.parse(data.body);
        // Set the selected story content and story ID
        setStoryContent(bodyData.story_text);
        setAudioURL(bodyData.audio_link);
        setStoryTitle(bodyData.story_title);
      } else {
        console.error('Failed to fetch story:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setUserName(null);
      setUserId(null);
      setMenuVisible(null);
    } catch (error) {
      console.error('Error signing out:', error);
      // Handle logout error
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'characterName') {
      setCharacterName(value);
    } else if (name === 'targetAge') {
      setTargetAge(value);
    } else if (name === 'storyDescription') {
      setStoryDescription(value);
    } else if (name === 'selectedVoice') { // Handle voice selection
      setSelectedVoice(value);
    } else if (name === 'selectedSpeed') { // Handle speed selection
      setSelectedSpeed(value);
    }
  };

  const handleFetchStory = async() => {
    if (!characterName.trim() || !targetAge.trim() || !storyDescription.trim()) {
      alert('Please fill out all fields before requesting the story.');
      return;
    }

    setLoading(true); // Start loading

    const apiKey = 'iCTpxJMiyG53J5TpRAad35zUdEMUxItj7UrNhv9K'; // API key
    const apiUrl = 'https://z1v8gw606l.execute-api.us-east-1.amazonaws.com/test/getstoryfull'; // API endpoint

    const question = 'Write an approximately 400 word story about a character named ' + characterName + '. The story theme should be about ' + storyDescription + '. The story should be appropriate for a ' + targetAge + ' year old, including all word choices. Give the story a creative title, and at the very beginning of the story say.."The title of this story is..." then say the title. At the end of the story pause for 3 seconds, then say "The End". Then thank the listener for their time and tell them you hope they enjoyed the story. Then tell them good bye.'

    const body = {
      question: question,
      speech_rate: selectedSpeed === 'Normal' ? '90%' : '85%', // Add speech_rate based on selected speed
      reader_voice: selectedVoice, // Add reader_voice based on selected voice
      user_id: userId  //Cognito ID for logged in user
    };

    const headers = new Headers({
      'Content-Type': 'application/json', // Set the content type
      'x-api-key': apiKey // Set the authorization header with the API key
    });
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });
    
      console.log('Response status:', response.status);
    
      if (response.ok) {
        const data = await response.json();
        setStoryContent(data.story);
        setAudioURL(data.audio_link); // Store the audio URL
        setStoryTitle(data.story_title);
      } else {
        const errorText = await response.text(); // Read the error response text
        setStoryContent('Failed to fetch story:', errorText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setStoryContent('An error occurred:', error);
    } finally {
      setLoading(false); // Stop loading
    }    

  };

  return (
    <div className="App">
      <header className="App-header">
        {user && (
          <button className="hamburger-icon" onClick={() => setMenuVisible(!menuVisible)}>
            <FontAwesomeIcon icon={faBars} size="2x" />
        </button>
        )}
        <div className={`menu ${menuVisible ? 'visible' : ''}`}>
        <p style={{ backgroundColor: '#007BFF' }}>
            {userName && <h3>{userName}</h3>}
          </p>
          <ul>
            <li>Menu Item 1</li>
            <li>Menu Item 2</li>
            {/* Add the logout button as the last item */}
            {user && (
              <li>
                <button className='link-button' onClick={handleLogout}>Logout</button>
              </li>
            )}
          </ul>
        </div>
        <h1 style={{ color: textColor }}>
          <u>Story Time</u>
        </h1>
        <h2 style={{ color: textColor }}> Welcome to a world of imagination and wonder!</h2>
      </header>

      <main>
        {user ? (
          <div className='MainContent'>

            <div className='RightMainContent'>
              <div className="user-input">
                <div className="input-group">
                    <label>Main Character's Name:</label>
                    <input
                      type="text"
                      name="characterName"
                      value={characterName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <label>Target Listener's Age:</label>
                    <input
                      type="text"
                      name="targetAge"
                      value={targetAge}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group">
                    <label>Brief Description of the Story:</label>
                    <textarea
                      name="storyDescription"
                      value={storyDescription}
                      onChange={handleInputChange}
                      rows={4} // You can adjust the number of rows based on your preference
                      cols={60} // You can adjust the number of columns based on your preference
                    />
                  </div>
                  <div className="input-group">
                    <label>Select Voice:</label>
                    <select
                      name="selectedVoice"
                      value={selectedVoice}
                      onChange={handleInputChange}
                    >
                      <option value="Brian">Brian</option>
                      <option value="Joanna">Joanna</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label>Select Read-Back Speed:</label>
                    <select
                      name="selectedSpeed"
                      value={selectedSpeed}
                      onChange={handleInputChange}
                    >
                      <option value="Normal">Normal</option>
                      <option value="Slower">Slower</option>
                    </select>
                  </div>
                  <button onClick={handleFetchStory}>
                    <span className="button-content">
                      {loading ? (
                        <React.Fragment>
                          <span className="loading-spinner"></span>
                          <span>Creating your story...</span>
                        </React.Fragment>
                      ) : (
                        "Request Your Story"
                      )}
                    </span>
                  </button>
              </div>
              {/* Display the returned story content */}
              {storyContent && (
                <div className={`story-card font-size-${fontSize}`}>
                  <div className="font-size-buttons">
                    <button onClick={() => setFontSize('small')}>Small</button>
                    <button onClick={() => setFontSize('medium')}>Medium</button>
                    <button onClick={() => setFontSize('large')}>Large</button>
                  </div>
                  <h2 style={{ color: primaryColor }}>{storyTitle}</h2>
                  {storyContent.split('\n\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                      <p>{paragraph}</p>
                      {index === 0 && audioURL && ( // Display the play button for the first paragraph
                        <div className="audio-player">
                          <audio controls>
                            <source src={audioURL} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                          <button onClick={() => document.querySelector("audio").play()}>
                            Play Audio
                          </button>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
              {stories.map((story, index) => (
                <div key={index} className="story-card">
                  <h2 style={{ color: primaryColor }}>Story Library</h2>
                  {storyLibrary.length > 0 ? (
                    <ul>
                      {storyLibrary.map((story) => (
                        <li key={story.story_id} onClick={() => handleStoryTitleClick(story.story_id)}>
                          <p style={{ cursor: 'pointer' }}>
                            {story.story_title}  -  (Created: {new Date(story.story_time).toLocaleString()})
                          </p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No stories in your library.</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <StoryTimeLogin />
        )}
      </main>
        <footer>
          <p style={{ color: '#BBBBBB' }}>&copy; 2023 Story Time  - v0.01</p>
        </footer>
  </div>  
  );
}

export default App;