// StoryTimeLogin.js

import React, { useState } from 'react';
import './App.css';
import { Auth } from 'aws-amplify';

function StoryTimeLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordChangeRequired, setIsPasswordChangeRequired] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false); // Track password change success
  const [user, setUser] = useState(null); // State to store the user

  const handleLogin = async () => {
    try {
      const user = await Auth.signIn(username, password);
      setUser(user); // Store the user in state
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsPasswordChangeRequired(true);
      } else {
        window.location.href = 'https://storytime.jhutch.net/'; // Redirect to main page
      }
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Invalid username or password');
    }
  };

  const handleChangePassword = async () => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      setIsPasswordChangeRequired(false);
      setIsPasswordChanged(true); // Set the password change success flag
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password. Please try again.');
    }
  };

  return (
    <div className="custom-login-container">
      <h2>Login to Your Account</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className="input-group">
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button onClick={handleLogin}>Login</button>

      {isPasswordChangeRequired && !isPasswordChanged && (
        <div>
          <h2>Change Your Password</h2>
          <div className="input-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <button onClick={handleChangePassword}>Change Password</button>
        </div>
      )}

      {isPasswordChanged && (
        <div>
          <p style={{ color: 'green' }}>Your password has been successfully changed.</p>
          <p>Please log in again using your new password.</p>
        </div>
      )}
    </div>
  );
}

export default StoryTimeLogin;