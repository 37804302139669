/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:7b7aaf2c-8c6a-49c8-8012-141e9be7016b',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_n5BxqaPci',
    aws_user_pools_web_client_id: '2jpbpc3dpvrqh5dn3u44ne9io9',
  };


export default awsmobile;
